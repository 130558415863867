.input-label {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  font-family: sans-serif;
}

.cross__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: red; */
  /* height: 250vh; */
  padding: 10px;
}

.cross__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cross__header h4 {
  font-family: sans-serif;
}

.cross__header__donwloadTeplate__btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #4e73df;
  color: white;
  border-radius: 5px;
  border: none;
  height: 35px;
  width: 160px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.cross__header__donwloadTeplate__btn:hover {
  color: white;
}

.tables-container {
  width: 100%;
  /* display: flex;
  flex-direction: row; */

  /* justify-content: space-evenly; */
  /* padding-left: 10px; */
  /* background-color: blue; */
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_header_icons {
  display: flex;
  width: 90px;
  justify-content: space-between;
  /* background-color: rebeccapurple; */
}

.table-section {
  width: 100%;
  /* margin-bottom: 20px; */
  /* background-color: blue; */
  /* Updated Code */
  /* height: 200vh; */
  /* display: flex;
  flex-direction: column; */
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.26);
  overflow: scroll;
  padding: 20px;
  /* box-sizing: border-box; */
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 18px;
}

.table-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: sans-serif;
}

/* .table-section {
  margin-bottom: 20px;
  box-sizing: border-box; Include padding and borders in the width */
/* padding: 10px;
} */

.main-btn {
  background-color: #4e73df !important;
  border: none;
}

.cross__header__buttons {
  /* width: 100%; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  /* background-color: red; */
}
.cross__header__buttons__col {
  display: flex;
  /* width: 200px; */
  /* background-color: green; */
}

.cross__header__input {
  background-color: #4e73df;
  color: white;
  border-radius: 6px;
  width: 140px;
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .table-section {
    width: 100%; /* Change to a single column layout on smaller screens */
  }
}

/* Style your tables here */
.cross-table-container {
  padding: 10px;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  overflow-x: scroll;
  /* height: 60vh; */
  /* background-color: red; */
}
