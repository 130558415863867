.chartComponent-container h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: sans-serif;
}

.chart-container {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* Media Screen Sizes */
/* @media(max-width: 323px){
  background-color: green;
} */

@media (min-width: 375px) and (max-width: 435px) {
  .chart-container {
    width: 270px;
    height: 280px;
    /* background-color: red; */
  }
}

@media (min-width: 435px) and (max-width: 630px) {
  .chart-container {
    width: 420px;
    height: 460px;
    /* background-color: blue; */
  }
}
