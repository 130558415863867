.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */
/* @import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700"; */

body {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the page takes the full height of the viewport */
  margin: 0; /* Remove default margin */
  overflow: hidden; /* Hide overflow to prevent double scrollbars */
}

/* Create a wrapper for the NavBar and content */
.wrapper {
  display: flex;
  flex: 1; /* Allow the wrapper to grow and take remaining space */
  overflow-y: hidden; /* Hide vertical overflow */
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
/* Sidebar Styles */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  /* max-width: 250px; */
  background: #5a5c69;
  color: #adb5bd;
  /* background: #354052;
  color: #adb5bd; */
  margin-left: -250px;
  transition: all 0.7s;
  padding: 10px; /* Remove padding to maximize content space */
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #5a5c69;
  color: #adb5bd;
  /* background: #313b4c;
  color: #adb5bd; */
  padding: 1rem;
  display: flex; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  border-bottom-color: #858796;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* justify-content: space-evenly; Add space between button and heading */
}

.image-class {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.sidebar-header h5 {
  font-size: 18px;
  margin: 0;
  padding-left: 10px;
  color: #fff;
  font-family: sans-serif;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  color: #adb5bd;
  font-weight: 300;
  padding: 10px 25px; /* Adjust padding for better spacing */
  display: flex; /* Center icon and text vertically */
  align-items: center;
  transition: background-color 0.2s;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-link.active {
  color: #e9ecef;
  /* background: #2d3646 !important; */
  width: 100%;
}

.nav-link {
  color: #e9ecef;
  text-decoration: none;
}

.nav-item:hover {
  /* background: #313b4c; */
  color: #fff;
  /* transition: 0.2s; */
}

.nav-item .svg-inline--fa {
  color: inherit;
  margin-right: 10px; /* Add space between icon and text */
}

/* Content Styles */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  flex: 1;
}
.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
}

/* Buttons */
.main-btn {
  background-color: #4e73df;
}

/* Screen Resizing */
@media only screen and (max-width: 575.98px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
  .card-row {
    display: grid;
    grid-template-columns: 1fr; /* 1 column */
    grid-gap: 16px;
  }

  /* On small screens: 2 columns */
  @media (min-width: 600px) {
    .card-row {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
  }

  /* On medium screens and larger: 4 columns */
  @media (min-width: 960px) {
    .card-row {
      grid-template-columns: repeat(4, 1fr); /* 4 columns */
    }
  }
}

/* Styling for ProjectCard */
