.cart-canvas-container {
  overflow: scroll;
  height: 100vh;
  /* background-color: red; */
}

.cart-inside-container {
  /* background-color: red; */
  /* width: 100%;
  height: 100%; */
}
.cart-canvas-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

/* Canvas Body */
.cart-canvas-body {
  /* background-color: green; */
  display: flex;
  justify-content: flex-start; /* When Cart is Empty change flex-start -> center */
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
}
.cart-canvas-body-empty {
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
}

.cart-canvas-item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 25vh;
  border-radius: 18px;
  margin-top: 40px;
  padding: 10px;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.26);
}

.cart-canvas-item-header {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */

  /* height: fit-content; */
  /* background-color: blue; */
}

.cart-canvas-item-List {
  list-style-type: none;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.cart-canvas-item-input {
  width: 100px;
  margin-top: 10px;
  border-radius: 5px;
  border-width: 1px;
}

.cart-canvas-item-header-context {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

/* Footer */
.cart-canvas-footer {
  display: flex;
  justify-content: center;
}

/* Buttons */
.cart-canvas-footer-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cart-canvas-item-button-save {
  background-color: rgb(60, 180, 60);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* Text Sizing / Font style */
